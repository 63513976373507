<template>
  <van-swipe :autoplay="3000" lazy-render>
    <van-swipe-item v-for="image in banners" :key="image">
      <img class="banner-img" :src="image.url" />
    </van-swipe-item>
  </van-swipe>
  <div class="brand-news">
    <div class="en">ABOUT {{ detailInfo.enableCompanyEnglishName }}</div>
    <div class="ch">{{ detailInfo.enableCompanyName }}</div>
  </div>
  <div class="company-introd">
    <video
      v-if="
        detailInfo.videos && detailInfo.videos[0] && detailInfo.videos[0].url
      "
      :poster="require('@/assets/img/公司优势.jpg')"
      :src="
        detailInfo.videos && detailInfo.videos[0] && detailInfo.videos[0].url
      "
      controls="controls"
    >
      您的浏览器不支持 video 标签。
    </video>
    <img
      v-else
      :src="detailInfo.pics && detailInfo.pics[0] && detailInfo.pics[0].url"
    />
    <div class="company-describe" v-html="detailInfo.introduce"></div>
  </div>
  <div class="brand-news">
    <div class="en">PRODUCTION INTRODUCTION</div>
    <div class="ch">产品介绍</div>
  </div>
  <div class="product-introd">
    <img
      :src="
        detailInfo.products &&
        detailInfo.products[0] &&
        detailInfo.products[0].url
      "
    />
  </div>
  <div
    v-if="
      detailInfo.partners &&
      detailInfo.partners[0] &&
      detailInfo.partners[0].url
    "
    class="big-img"
  >
    <img
      :src="
        detailInfo.partners &&
        detailInfo.partners[0] &&
        detailInfo.partners[0].url
      "
    />
  </div>
  <div class="footer">
    <div class="cover-img">
      <div
        v-if="
          detailInfo.otherQrCodes &&
          detailInfo.otherQrCodes[0] &&
          detailInfo.otherQrCodes[0].url
        "
        class="code-box"
      >
        <p>
          {{
            detailInfo.otherQrCodes &&
            detailInfo.otherQrCodes[0] &&
            detailInfo.otherQrCodes[0].name.split(".")[0]
          }}
        </p>
        <img
          :src="
            detailInfo.otherQrCodes &&
            detailInfo.otherQrCodes[0] &&
            detailInfo.otherQrCodes[0].url
          "
        />
      </div>
      <div
        class="code-box"
        v-if="
          detailInfo.companyOfficialAccountQrCodes &&
          detailInfo.companyOfficialAccountQrCodes[0] &&
          detailInfo.companyOfficialAccountQrCodes[0].url
        "
      >
        <p>
          {{
            detailInfo.companyOfficialAccountQrCodes &&
            detailInfo.companyOfficialAccountQrCodes[0] &&
            detailInfo.companyOfficialAccountQrCodes[0].name.split(".")[0]
          }}
        </p>
        <img
          :src="
            detailInfo.companyOfficialAccountQrCodes &&
            detailInfo.companyOfficialAccountQrCodes[0] &&
            detailInfo.companyOfficialAccountQrCodes[0].url
          "
        />
      </div>
      <div class="contact-method">
        <div v-if="detailInfo.contactNumber" class="phone-wrap">
          <img src="../assets/img/phone.png" alt="" />
          <div>合作热线：{{ detailInfo.contactNumber }}</div>
        </div>
        <div v-if="detailInfo.email" class="email-wrap">
          <img src="../assets/img/email.png" alt="" />
          <div>合作邮箱：{{ detailInfo.email }}</div>
        </div>
      </div>
    </div>
    <div class="icon-wrap">
      <img src="../assets/img/品牌矩阵反白.png" />
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import { getAssignPlatDetail } from "@/api/index.js";
import { useRoute } from "vue-router";
import { trackEvent } from "@/utils/tracklog.js";

const banners = ref([]);
const detailInfo = reactive({});
const route = useRoute();

onMounted(() => {
  const id = route.params.id;
  getDetail(id);
});

// watch(
//   () => route.params.id,
//   async () => {
//     route.params.id && getDetail(route.params.id);
//   }
// );

const getDetail = (id) => {
  getAssignPlatDetail(id).then((res) => {
    Object.assign(detailInfo, res);
    const { slideShows, enableCompanyName } = res;
    banners.value = slideShows;
    //埋点 业务中台
    trackEvent(
      `${id == 1 ? "会员体系" : "业务中台"}-${enableCompanyName}`,
      "浏览",
      `${id == 1 ? "会员体系" : "业务中台"}-${enableCompanyName}`,
      {},
      0,
      new Date().getTime()
    );
  });
};
</script>

<style lang="scss" scoped>
.banner-img {
  width: 100%;
}
.brand-news {
  position: relative;
  margin: 30rem auto 50rem;
  width: fit-content;
  .en {
    font-size: $font-size-large-x;
    color: #eee;
  }
  .ch {
    font-size: $font-size-large-x;
    position: absolute;
    top: 46%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }
}
.company-introd {
  padding: 0 30rem;
  img,
  video {
    width: 100%;
  }
  .company-describe {
    margin-top: 20rem;
    line-height: 40rem;
  }
}
.big-img {
  font-size: 0;
}
img {
  max-width: 750rem;
}
.footer {
  background: #005250;
  padding: 20rem;
  overflow: hidden;
  height: auto;
  .cover-img {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    padding-top: 40rem;
    color: #fff;
    font-size: 12rem;
    .code-box {
      margin-right: 72rem;
      p {
        text-align: center;
        margin-bottom: 15rem;
        text-align: center;
      }
      img {
        width: 100rem;
        height: 100rem;
        display: block;
        margin: 0 auto;
      }
    }
    .contact-method {
      margin-top: 25rem;
      img {
        width: 40rem;
        height: 40rem;
        margin-right: 30rem;
      }
      .phone-wrap {
        margin-bottom: 20rem;
      }
      .phone-wrap,
      .email-wrap {
        display: flex;
        align-items: center;
      }
    }
  }
  .descible-wrap {
    display: flex;
    justify-content: space-between;
    font-size: 14rem;
    color: #b7b7b7;
    padding: 25rem 310rem;
    border-top: 1rem solid #eee;
  }
  .icon-wrap > img {
    max-width: 100%;
  }
}
</style>